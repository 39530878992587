import axios from "axios";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { MdImageSearch } from "react-icons/md";
import { Link } from "react-router-dom";

const Search = ({ handleClose2 }) => {
  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const handleChange = (e) => {
    const searchWord = e.target.value;
    setWord(searchWord);
    const newFilterData = searchData.filter((value) =>
      value.name.toLowerCase().includes(searchWord.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const handleClose = () => {
    setWord();
    setFilterData([]);
  };

  return (
    <div className="bg-gray-400/10 fixed top-5  border   rounded-md overflow-hidden">
      <div className="search-container flex flex-col    md:w-[20rem] lg:w-[30rem] items-center justify-center">
        <div className="flex flex-col items-center gap-2 w-full relative z-[9999]">
          <input
            onChange={handleChange}
            type="text"
            placeholder="Search Items......"
            className=" bg-transparent border p-3 px-8  w-full rounded-md focus:outline-none "
          />
          {filterData.length === 0 ? (
            <button className="absolute bg-[#C3161C] rounded-br-md rounded-tr-md  font-extrabold text-white py-2 px-4 top-0 right-0 bottom-0">
              <FiSearch />
            </button>
          ) : (
            <IoIosClose
              onClick={handleClose}
              className="absolute right-3  text-2xl text-gray-500"
            />
          )}

          {filterData.length !== 0 && (
            <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
              {filterData.slice(0, 5).map((product) => (
                <Link
                  onClick={handleClose}
                  key={product.id}
                  to={`/productdetails/${product.id}`}
                  className="product-card flex items-center    gap-2"
                >
                  <div className="product-image w-20 h-20 overflow-hidden">
                    <img
                      src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="product-content text-black w-full text-sm overflow-hidden">
                    <h4 className="font-semibold">{product?.brand_name}</h4>
                    <p className="truncate text-lg ">{product?.name}</p>
                    <span className="text-gray-400">{product?.unit_price}</span>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
