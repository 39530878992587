import React, { useCallback, useEffect, useState } from "react";
import ProductImageCarasol from "./ProductImageCarasol";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";

import { BsFillCartDashFill, BsFillCartPlusFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";

const OrderNowModal = ({
  product,

  handleClose,
}) => {
  // const { items: size } = useSelector((state) => state.sizes);

  const [singleProduct, setSingleProduct] = useState({});
  const [imageThamb, setImageThamb] = useState([]);
  // const [color, setColor] = useState([]);

  const [cartQuantity, setCartQuantity] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);

  // const [size, setSize] = useState([]);

  // const [colorAtr, setColorAtr] = useState("");

  // const [sizeAtr, setSizeAtr] = useState("");

  const [response, setResponse] = useState({});
  const [size, setSize] = useState([]);

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const state = {
    button: "add_to_cart",
  };

  const delay = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const clearFunction = () => {
    setResponse("");
  };

  useEffect(() => {
    if (selectedOption) {
      fetch(`${process.env.REACT_APP_URL}/api-findproductstock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productid: product?.id,
          variantid: selectedOption,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data as needed
          console.log("Response from server:", data);
          setResponse(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedOption, product?.id]);

  const handleRadioClick = (option) => {
    setSelectedOption(option);
    setCartQuantity(1);
  };

  useEffect(() => {
    const singleProduct = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-products/${id}`
      );
      return setSingleProduct(res.data);
    };
    singleProduct();
  }, [id]);

  useEffect(() => {
    const SizeAttribute = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-specificattribute/${product?.id}`
      );
      return setSize(res.data);
    };
    SizeAttribute();
  }, [product?.id]);

  useEffect(() => {
    const imageThambs = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-thumb/${product?.id}}`
      );

      return setImageThamb(res.data);
    };
    imageThambs();
  }, [product?.id]);

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  const handleBuyNow = () => {
    if (!selectedOption) {
      toast.warn("Select your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      dispatch(
        addToSingleCart({
          ...product,
          cartQuantity,
          sizeAtr: selectedOption,
          response: response?.price,
        })
      );
      navigation("/checkout");
    }
  };
  const handleAddToCart = () => {
    if (!selectedOption) {
      toast.warn("Select your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      dispatch(
        addToSingleCart({
          ...product,
          cartQuantity,
          sizeAtr: selectedOption,
          response: response?.price,
        })
      );
      // navigation("/checkout");
      setCartQuantity(1);
    }
  };

  // const handleAddToCart = () => {
  //   if (!selectedOption) {
  //     toast.warn("Select your product size plse", {
  //       position: "bottom-left",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   } else {
  //     dispatch(
  //       addtoCart({
  //         ...product,
  //         cartQuantity,
  //         sizeAtr: selectedOption,
  //         response: response.price,
  //       })
  //     );
  //     setSelectedOption(null);
  //   }
  // };
  return (
    <>
      <div
        onClick={handleClose}
        className="bg-black/40   z-[9998]  fixed top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden"
      ></div>
      <div className="p-2 z-[9999] bg-white  fixed  shadow-xl top-0 right-0 left-0 bottom-0 w-full h-auto mr-5  md:container md:mx-auto my-10 overflow-y-scroll">
        <div className="flex items-center justify-end">
          <button
            onClick={handleClose}
            className="my-2 flex items-end justify-end"
          >
            <RxCross1 />
          </button>
        </div>
        <div className="grid  lg:grid-cols-[40%_40%_20%] gap-5">
          <div className="product image h-auto lg:h-[40rem]">
            <div className="w-full h-full">
              <ProductImageCarasol
                productImg={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                imageThamb={imageThamb}
              />
            </div>
          </div>
          <div className="product-details flex flex-col gap-3  p-2">
            <div className="text-2xl font-semibold">{product?.name}</div>
            {/* size and color */}

            {/* product quentity */}

            <div className="relative w-full rounded-md overflow-hidden">
              <input
                type="number"
                value={cartQuantity}
                onChange={(e) => setCartQuantity(e.target.value)}
                className="w-full py-3 text-center border border-red-200"
              />

              {/* <span>{cartQuantity}</span> */}
              <button
                onClick={() => handleDecrease(product)}
                className="absolute top-0 bottom-0 left-0 flex items-center justify-center bg-red-600 p-5 text-white"
              >
                <span>
                  <BsFillCartDashFill />
                </span>
              </button>
              <button
                onClick={() => handleIncrease(product)}
                className="absolute top-0 bottom-0 right-0 flex items-center justify-center bg-red-600 p-5 text-white"
              >
                <span>
                  <BsFillCartPlusFill />
                </span>
              </button>
            </div>

            {/* add to cart and bye now button */}

            <div>
              <div className="flex flex-col ">
                <>
                  <div className="flex flex-col  gap-5 mb-5">
                    {size?.length > 0 && (
                      <div className="color-atr flex items-center justify-start gap-7 mb-2">
                        <h4 className="text-2xl">
                          <span>Available size</span>
                          <span>:</span>
                        </h4>
                        <div className="flex flex-wrap items-center justify-start gap-4">
                          {size?.map((s, index) => (
                            <span key={s.id}>
                              <button
                                key={index}
                                onClick={() => handleRadioClick(s.variant)}
                                className={`border border-red-500 py-3 px-4 ${
                                  selectedOption === s.variant
                                    ? "bg-red-600 text-white"
                                    : ""
                                }`}
                              >
                                {s.variant}
                              </button>
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="flex gap-5 items-center">
                      <h4 className="text-2xl">Price :</h4>
                      <span className="text-red-500 font-normal text-3xl">
                        {response?.price > 0 ? (
                          <div>{response?.price}</div>
                        ) : (
                          <div>
                            <span>select your product size</span>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col gap-5">
                    <button
                      disabled={!response?.price}
                      onClick={handleAddToCart}
                      className={`${
                        !response?.price
                          ? "bg-red-600 cursor-not-allowed"
                          : "bg-red-600 hover:bg-[#3A3A3A]"
                      }  p-4 w-full rounded-md  text-white duration-300`}
                    >
                      Add to cart
                    </button>
                    <button
                      disabled={!response?.price}
                      className={` ${
                        response.price
                          ? "bg-red-600 hover:bg-[#3A3A3A]"
                          : "bg-red-600 cursor-not-allowed"
                      }    p-4 w-full rounded-md  text-white duration-300`}
                      onClick={handleBuyNow}
                    >
                      Buy Now
                    </button>
                  </div>
                </>
              </div>
            </div>

            {/* short descriptin */}

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderNowModal;
